export function formateDate(fmt, date) {
  let ret;
  let newDate = new Date(date);
  const opt = {
    "Y+": newDate.getFullYear().toString(), // 年
    "m+": (newDate.getMonth() + 1).toString(), // 月
    "d+": newDate.getDate().toString(), // 日
    "H+": newDate.getHours().toString(), // 时
    "M+": newDate.getMinutes().toString(), // 分
    "S+": newDate.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}
//使用递归的方式实现数组、对象的深拷贝
export function deepClone(obj) {
  //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
  var objClone = Array.isArray(obj) ? [] : {};
  //进行深拷贝的不能为空，并且是对象或者是
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}


/**
 * 获取URL参数
 * variable 地址 URL
 */
 export function getQueryVariable(variable) {
  let query = window.location.href.split('?')[1];
  if (!query) return (null)
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (null);
}